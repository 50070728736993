// extracted by mini-css-extract-plugin
export var button = "PlasmicCtaBlock-module--button--m3315";
export var column__iQd = "PlasmicCtaBlock-module--column__iQd--omz+9";
export var column__mmOqi = "PlasmicCtaBlock-module--column__mmOqi--eMHC4";
export var container = "PlasmicCtaBlock-module--container--dmR05";
export var h2 = "PlasmicCtaBlock-module--h2--64y9k";
export var outer = "PlasmicCtaBlock-module--outer--YLVor";
export var root = "PlasmicCtaBlock-module--root--baQfw";
export var svg__cXhM = "PlasmicCtaBlock-module--svg__cXhM--yq1Ej";
export var svg__dqvTd = "PlasmicCtaBlock-module--svg__dqvTd--v8u0K";
export var text__d062A = "PlasmicCtaBlock-module--text__d062A--syInJ";
export var text__nbu8R = "PlasmicCtaBlock-module--text__nbu8R--1vdTX";