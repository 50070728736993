// extracted by mini-css-extract-plugin
export var __wab_imgSpacer = "PlasmicTestimonial-module--__wab_img-spacer--wadbK";
export var column___3S0La = "PlasmicTestimonial-module--column___3S0La--BOceI";
export var column__po6S = "PlasmicTestimonial-module--column__po6S--PMmik";
export var column__rfzth = "PlasmicTestimonial-module--column__rfzth--ynyVA";
export var columns = "PlasmicTestimonial-module--columns--BRYor";
export var h2 = "PlasmicTestimonial-module--h2--QaufL";
export var h5 = "PlasmicTestimonial-module--h5--V-TH9";
export var img = "PlasmicTestimonial-module--img--qlmIY";
export var root = "PlasmicTestimonial-module--root--IeFXE";
export var text = "PlasmicTestimonial-module--text--kEPHv";